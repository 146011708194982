"use client";
import {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    Checkbox,
    Image,
    Input,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    useDisclosure
} from "@nextui-org/react";
import {EyeFilledIcon} from "./EyeFilledIcon";
import {EyeSlashFilledIcon} from "./EyeSlashFilledIcon";
export default function Page() {
    const {isOpen, onOpen, onOpenChange} = useDisclosure();


    const [isVisible, setIsVisible] = useState(false);
    const toggleVisibility = () => setIsVisible(!isVisible);

    const [nbColis, setNbColis] = useState(0);
    const [dateReception, setDateReception] = useState("");
    const [montantTTC, setMontantTTC] = useState(0);
    const [nbArticles, setNbArticles] = useState(0);
    const [numeroCommande, setNumeroCommande] = useState("");
    const [identifiantCompte, setIdentifiantCompte] = useState("");
    const [motDePasseCompte, setMotDePasseCompte] = useState("");
    const [motDePasseEmail, setMotDePasseEmail] = useState("");
    const [userStory, setUserStory] = useState("");

    const [recue, setRecue] = useState(true);

    const [store, setStore] = useState("");

    const [list, setList] = useState(null);


    useEffect( () => {
        const Telegram = window.Telegram;
        Telegram.WebApp.ready();
        Telegram.WebApp.expand();
        fetch("/api/stores").then((res) => res.json()).then((data) => {
            setList(data);
        });

    }, []);

    return <div className="mt-4 px-4 gap-4 grid">
        <h1 className="text-2xl font-bold text-center">Select a store</h1>
        <Modal
            scrollBehavior={"inside"}
            isOpen={isOpen}
            onOpenChange={() => {
                setNbColis(0);
                setDateReception("");
                setMontantTTC(0);
                setNbArticles(0);
                setNumeroCommande("");
                setIdentifiantCompte("");
                setMotDePasseCompte("");
                setMotDePasseEmail("");
                onOpenChange();
                setUserStory("");
            }}
            size={"full"}
        >
            <ModalContent>
                {(onClose) => (<>
                    <ModalHeader className="flex flex-col gap-1">{store}</ModalHeader>
                    <ModalBody>
                        <Checkbox
                            defaultSelected
                            checked={recue}
                            onChange={() => {setRecue(!recue)}}
                        >
                            Avez-vous déjà reçu la commande ?
                        </Checkbox>
                        <Input
                            type="number"
                            label="Nombre de colis reçus"
                            min={1}
                            isRequired={recue}
                            defaultValue={nbColis}
                            onChange={(e) => setNbColis(e.target.value)}
                            isDisabled={!recue}
                        />
                        <Input
                            type="date"
                            label="Date de réception"
                            isRequired={recue}
                            defaultValue={dateReception}
                            onChange={(e) => setDateReception(e.target.value)}
                            isDisabled={!recue}
                        />
                        <Input
                            type="number"
                            label="Montant TTC de la commande"
                            placeholder="0.00"
                            min={0}
                            isRequired={true}
                            endContent={<div className="pointer-events-none flex items-center">
                                <span className="text-default-400 text-small">€</span>
                            </div>}
                            defaultValue={montantTTC}
                            onChange={(e) => setMontantTTC(e.target.value)}
                        />
                        <Input
                            type="number"
                            label="Nombre d'articles"
                            min={1}
                            isRequired={true}
                            defaultValue={nbArticles}
                            onChange={(e) => setNbArticles(e.target.value)}
                        />
                        <Input
                            className="mt-4"
                            type="text"
                            label="Numero de la commande"
                            defaultValue={numeroCommande}
                            onChange={(e) => setNumeroCommande(e.target.value)}
                        />
                        <Input
                            type="email"
                            label="Identifiant du compte"
                            placeholder="Email"
                            defaultValue={identifiantCompte}
                            onChange={(e) => setIdentifiantCompte(e.target.value)}
                        />
                        <Input
                            type={isVisible ? "text" : "password"}
                            label="Mot de passe du compte"
                            placeholder="Mot de passe"
                            endContent={<button className="focus:outline-none" type="button"
                                                onClick={toggleVisibility}>
                                {isVisible ? (<EyeSlashFilledIcon
                                    className="text-2xl text-default-400 pointer-events-none"/>) : (
                                    <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none"/>)}
                            </button>}
                            defaultValue={motDePasseCompte}
                            onChange={(e) => setMotDePasseCompte(e.target.value)}
                        />
                        <Input
                            type={isVisible ? "text" : "password"}
                            label="Mot de passe de l'email"
                            placeholder="Mot de passe de l'email"
                            endContent={<button className="focus:outline-none" type="button"
                                                onClick={toggleVisibility}>
                                {isVisible ? (<EyeSlashFilledIcon
                                    className="text-2xl text-default-400 pointer-events-none"/>) : (
                                    <EyeFilledIcon className="text-2xl text-default-400 pointer-events-none"/>)}
                            </button>}
                            defaultValue={motDePasseEmail}
                            onChange={(e) => setMotDePasseEmail(e.target.value)}
                        />
                        <Input
                            className="mt-4"
                            type="text"
                            label="Comment avez-vous connu nos services"
                            defaultValue={userStory}
                            onChange={(e) => setUserStory(e.target.value)}
                        />

                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" variant="light" onPress={onClose}>
                            Annuler
                        </Button>
                        <Button color="primary" onPress={() => {
                            const Telegram = window.Telegram;
                            Telegram.WebApp.sendData(JSON.stringify({
                                "store": store,
                                "nbColis": nbColis,
                                "dateReception": dateReception,
                                "montantTTC": montantTTC,
                                "nbArticles": nbArticles,
                                "numeroCommande": numeroCommande,
                                "identifiantCompte": identifiantCompte,
                                "motDePasseCompte": motDePasseCompte,
                                "motDePasseEmail": motDePasseEmail,
                                "userStory": userStory,
                            }));
                            Telegram.WebApp.close();
                        }}>
                            Créer le ticket
                        </Button>
                    </ModalFooter>
                </>)}
            </ModalContent>
        </Modal>
        <div className="gap-2 grid grid-cols-2 sm:grid-cols-4">
            {list && list.map((item, index) => (<Card shadow="sm" key={index} isPressable onPress={() => {
                setStore(item.title);
                onOpen();
            }}>
                <CardBody className="overflow-visible p-0">
                    <Image
                        shadow="sm"
                        radius="lg"
                        width="100%"
                        alt={item.title}
                        className="w-full object-cover h-[140px]"
                        src={item.img}
                    />
                </CardBody>
                <CardFooter className="text-small">
                    <b>{item.title}</b>
                </CardFooter>
            </Card>))}
        </div>
    </div>
}